import { IBreadcrumbsFields } from 'contentful-types'

import { withContentful } from 'src/siteBuilder/renderer/contentful/withContentful'

import Breadcrumbs from './Breadcrumbs'

const ContentfulBreadcrumbs = withContentful<IBreadcrumbsFields>(Breadcrumbs, {
  breadcrumbs: (props) => {
    if (!props.fields.breadcrumb) {
      return null
    }
    return props.fields.breadcrumb
      .filter((breadcrumb) => breadcrumb.fields?.label && breadcrumb.fields?.slug)
      .map((breadcrumb) => ({
        id: breadcrumb.sys.id,
        label: breadcrumb.fields.label,
        slug: breadcrumb.fields.slug,
      }))
  },
  backgroundColor: (props) => props.fields.backgroundColor,
})

export default ContentfulBreadcrumbs
