import { Breadcrumbs as AlliumBreadcrumb, FlexGrid, Box } from '@telus-uds/components-web'
import { pickDataAttrs } from '../../../utils/dataAttributes'
import { BreadcrumbBgColorWrapper } from './styles'

export type BreadcrumbProps = {
  breadcrumbs: ItemProps[]
  backgroundColor?: string
  inverse?: boolean
}

type ItemProps = {
  id: string
  label: string
  slug: string
}

const Breadcrumbs = ({ breadcrumbs, backgroundColor, inverse = false, ...rest }: BreadcrumbProps) => {
  if (!breadcrumbs || breadcrumbs.length === 0) return null

  const items = breadcrumbs.map((item) => (
    <AlliumBreadcrumb.Item key={item.id} href={item.slug}>
      {item.label}
    </AlliumBreadcrumb.Item>
  ))

  return (
    <BreadcrumbBgColorWrapper bgColor={backgroundColor}>
      <FlexGrid>
        <FlexGrid.Row>
          <FlexGrid.Col>
            <Box vertical={2}>
              <AlliumBreadcrumb
                //@ts-ignore
                variant={!!inverse ? { inverse } : undefined}
                data-testid={'allium-breadcrumb-testid'}
                {...pickDataAttrs(rest)}
              >
                {items}
              </AlliumBreadcrumb>
            </Box>
          </FlexGrid.Col>
        </FlexGrid.Row>
      </FlexGrid>
    </BreadcrumbBgColorWrapper>
  )
}

export default Breadcrumbs
