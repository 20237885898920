import styled from 'styled-components'
import Palette from '@telus-uds/palette-allium/build/web/palette'
import koodoPalette from '@telus-uds/palette-koodo'
import { VIEWPORT_SIZES } from '@/constants/VIEWPORT'

const { color: koodoColors } = koodoPalette

type HexColorProps = {
  color: string
  viewport: string
}

const getKoodoTriColorGradient = (color1: string, color2: string, color3: string): string => {
  const bannerWidth = '1200px'
  const bannerStart = `calc((100% - ${bannerWidth}) / 2)`
  const bannerEnd = `calc(${bannerWidth} + ${bannerStart})`
  const bannerCutWidth = '150px'
  const bannerCutStart = `calc(${bannerEnd} - 160px)`
  const bannerCutEnd = `calc(${bannerCutStart} + ${bannerCutWidth})`

  return `linear-gradient(90deg,
    ${color1} 0px,
    ${color1} ${bannerCutStart},
    ${color2} ${bannerCutStart},
    ${color2} ${bannerCutEnd},
    ${color3} ${bannerCutEnd},
    ${color3} 100%)`
}

const koodoHexColors = (input: HexColorProps) => {
  const viewport = input.viewport

  return {
    'Koodo Brand Orange': koodoColors.orange,
    'Koodo Brand Green': koodoColors.green,
    'Koodo Brand Pink': koodoColors.pink,
    'Koodo Brand Blue': koodoColors.blue,
    'Koodo Brand Purple': koodoColors.purple,
    'Koodo Brand Gradient OGP':
      viewport === 'mobile'
        ? koodoColors.orange
        : getKoodoTriColorGradient(koodoColors.orange, koodoColors.green, koodoColors.pink),
    'Koodo Brand Gradient OPB':
      viewport === 'mobile'
        ? koodoColors.orange
        : getKoodoTriColorGradient(koodoColors.orange, koodoColors.purple, koodoColors.blue),
    'Koodo Brand Gradient OPP':
      viewport === 'mobile'
        ? koodoColors.orange
        : getKoodoTriColorGradient(koodoColors.orange, koodoColors.purple, koodoColors.pink),
    'Koodo Brand Gradient GOP':
      viewport === 'mobile'
        ? koodoColors.green
        : getKoodoTriColorGradient(koodoColors.green, koodoColors.orange, koodoColors.pink),
    'Koodo Brand Gradient GPO':
      viewport === 'mobile'
        ? koodoColors.green
        : getKoodoTriColorGradient(koodoColors.green, koodoColors.pink, koodoColors.orange),
    'Koodo Brand Gradient GPB':
      viewport === 'mobile'
        ? koodoColors.green
        : getKoodoTriColorGradient(koodoColors.green, koodoColors.purple, koodoColors.blue),
    'Koodo Brand Gradient BGO':
      viewport === 'mobile'
        ? koodoColors.blue
        : getKoodoTriColorGradient(koodoColors.blue, koodoColors.green, koodoColors.orange),
    'Koodo Brand Gradient BPO':
      viewport === 'mobile'
        ? koodoColors.blue
        : getKoodoTriColorGradient(koodoColors.blue, koodoColors.pink, koodoColors.orange),
    'Koodo Brand Gradient BPP':
      viewport === 'mobile'
        ? koodoColors.blue
        : getKoodoTriColorGradient(koodoColors.blue, koodoColors.purple, koodoColors.pink),
    'Koodo Brand Gradient BOPink':
      viewport === 'mobile'
        ? koodoColors.blue
        : getKoodoTriColorGradient(koodoColors.blue, koodoColors.orange, koodoColors.pink),
    'Koodo Brand Gradient BOPurple':
      viewport === 'mobile'
        ? koodoColors.blue
        : getKoodoTriColorGradient(koodoColors.blue, koodoColors.orange, koodoColors.purple),
    default: 'transparent',
  }
}

type StyledProps = {
  bgColor?: string
}

const getHexColor = (input: HexColorProps): string => {
  const colors = {
    'Alabaster Grey': Palette.color.greyAlabaster,
    'Athens Grey': Palette.color.greyAthens,
    'Grey Shuttle': Palette.color.greyShuttle,
    'Grey Charcoal': Palette.color.greyCharcoal,
    'Grey Thunder': Palette.color.greyThunder,
    'Purple Telus': Palette.color.purpleTelus,
    ...koodoHexColors(input),
    None: 'transparent',
  }

  return colors[input.color] ? colors[input.color] : colors.None
}

export const BreadcrumbBgColorWrapper = styled.div<StyledProps>`
  background: ${(props: any) => getHexColor({ color: props.bgColor ?? 'None', viewport: 'mobile' })};

  @media (min-width: ${VIEWPORT_SIZES.lg}px) {
    background: ${(props: any) => `${getHexColor({ color: props.bgColor ?? 'None', viewport: 'desktop' })}`};
  }
`
